/* ---------------------------------------------
 Box
 --
 An all-purpose rhythm-creating wrapper
--------------------------------------------- */

$box-sizes: 0 1 2 3 4 5 6 7 10;

.box {
  display: block;
}

.box_75 {
  width: 75%;
}

.box_center {
  margin: 0 auto;
}

.box_relative {
  position: relative;
}

.box_textCenter {
  text-align: center;
}

.box_bottom4_5 {
  padding-bottom: rhythm(4.5);
}

@mixin createBoxDimensions($resolution: '') {
  @each $box-size in $box-sizes {
    .box_ {
      &top#{$box-size}#{$resolution} {
        padding-top: rhythm($box-size);
      }
      &bottom#{$box-size}#{$resolution} {
        padding-bottom: rhythm($box-size);
      }
      &left#{$box-size}#{$resolution} {
        padding-left: rhythm($box-size);
      }
      &right#{$box-size}#{$resolution} {
        padding-right: rhythm($box-size);
      }
      &flats#{$box-size}#{$resolution} {
        padding-top: rhythm($box-size);
        padding-bottom: rhythm($box-size);
      }
      &sides#{$box-size}#{$resolution} {
        padding-left: rhythm($box-size);
        padding-right: rhythm($box-size);
      }
    }
  }
}

@include createBoxDimensions();

@each $bp-name, $bp-size in $breakpoints {
  @include breakpoint($bp-size) {
    @include createBoxDimensions(capitalize($bp-name));
  }
}

@include breakpoint(breakpoint-size('md')) {
  .box_430Md {
    width: 43rem;
  }
}
