/* ---------------------------------------------
 Btn
 --
 All variations of the design's button styles
--------------------------------------------- */

.btn {
  display: inline-block;
  font-family: $FONT_FAMILY_PRIMARY;
  text-transform: uppercase;
  background-color: $COLOR_GREY_DARK;
  color: $COLOR_LIGHTEST;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: .1rem;
  line-height: 1.2;
  min-width: 12.8rem;
  padding: 1.4rem rhythm(4) 1.5rem rhythm(4);
  text-align: center;
  transition: background-color 0.3s $EASEOUT_QUAD;
  width: 100%;

  &:active {
    background-color: $COLOR_GREY;
  }

  &:disabled {
    background-color: $COLOR_GREY_LIGHTEST;
    cursor: unset;
  }
}

.btn_full {
  width: 100% !important;
}

.btn_pill {
  border-radius: rhythm(3);
}

@include breakpoint(breakpoint-size('md')) {
  .btn {
    width: auto;
  }
}

@include breakpoint(breakpoint-size('lg')) {
  .btn {
    &:hover {
      background-color: $COLOR_GREY;
    }
  }
}
