@font-face {
  font-family: 'circular';
  src: url('assets/fonts/circular-book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'circular';
  src: url('assets/fonts/circular-bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
