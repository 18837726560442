/* ---------------------------------------------
  Album Section
--------------------------------------------- */
.albums {
  height: 100vh;
}

.albumSection {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 25rem;
  padding: rhythm(2);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .0;
    z-index: -1;
    background: url('assets/images/grey_@2X.png') repeat;
    background-size: 200%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &:hover {
    .albumSection-img {
      // animation: peak .4s $EASEOUT_QUAD 1;
    }
  }
}

.albumSection-img {
  max-width: 100%;
  position: absolute;
  right: -12.5rem;
  top: 50%;
  transform: rotate(-27deg) translateY(-50%);
  box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.45);
  transition: transform .2s $EASEINOUT_QUAD;
  z-index: 2;
}

.albumSection-songTitles {
  position: absolute;
  right: 10rem;
  transform: translateX(100%);
  top: -2rem;
  opacity: .1;
}

@keyframes peak {
  0% {
    transform: rotate(-27deg) translateY(-50%);
  }
  50% {
    transform: rotate(-20deg) translateY(-50%);
  }
  100% {
    transform: rotate(-27deg) translateY(-50%);
  }
}
