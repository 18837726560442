/* ---------------------------------------------
 Utility Classes
--------------------------------------------- */

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.noScroll {
  overflow: hidden;
  position: fixed;
}

html:not(.js-tabbing-active) :focus {
  outline: none;
}
