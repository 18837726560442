/* ---------------------------------------------
 Icon
 --
 A wrapper for the design's svg icons
--------------------------------------------- */

.icon {
  display: inline-block;
  position: relative;

  // Force constrained layout to ensure predictable svg rendering
  > * {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }
}

.icon_spotify {
  .primaryFill {
    fill: rgba($COLOR_LIGHTEST, .255);
    transition: fill .3s $EASEINOUT_QUAD;
  }

  &:hover {
    .primaryFill {
      fill: rgba($COLOR_LIGHTEST, 1);
    }
  }
}

@mixin createIconSize($size, $resolution: '') {
  .icon_ {
    &#{$size}#{$resolution} {
      height: #{$size/10}rem;
      width: #{$size/10}rem;
    }
  }
}

@include createIconSize(10);
@include createIconSize(16);
@include createIconSize(24);
@include createIconSize(33);


@include breakpoint(breakpoint-size('md')) {
  .icon_store {
    height: 3.2rem;
    width: 10.6rem;

    &Hero {
      height: 4rem;
      width: 13rem;
    }

    &:hover .primaryFill {
      fill: $COLOR_GREY_LIGHTEST;
    }
  }
}
